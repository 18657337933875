import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import slugify from 'slugify'

import * as Yup from 'yup'
import { Formik, FormikHelpers, FormikProps } from 'formik'

import { Button } from '@paljs/ui/Button'
import { InputGroup } from '@paljs/ui/Input'
import Alert from '@paljs/ui/Alert'

import * as api from '../../util/api'
import { RegisterProps } from '../../util/api/types'

import Auth, { Group } from '../../components/Auth'

import useAuth from '../../util/useAuth'

interface UserData {
  email: string
  fullName: string
  password: string
  passwordConfirm: string
}

const initialValues: UserData = {
  email: '',
  fullName: '',
  password: '',
  passwordConfirm: '',
}

type OnSubmitProps = (values: UserData, formikHelpers: FormikHelpers<UserData>) => void

const validationSchema = Yup.object({
  email: Yup.string().email().required('Email Address is required'),
  fullName: Yup.string().required('Full name is required'),
  password: Yup.string().min(6).required('Password is required'),
  passwordConfirm: Yup.string()
    .when('password', {
      is: (password: string) => password?.length > 0,
      then: Yup.string().required('Please confirm your password'),
    })
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const Login: React.FC = () => {
  const { getUser } = useAuth()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser()
      .then(() => navigate('/'))
      .catch(() => setLoading(false))
  }, [])

  const onSubmit: OnSubmitProps = async (data, actions) => {
    let username = data.email.replace(/[*+~.()'"!:@]/g, '-')
    username = slugify(username, { lower: true })
    actions.setErrors({})

    const values: RegisterProps = {
      username,
      fullName: data.fullName,
      email: data.email,
      password: data.password,
      blocked: false, // TODO: change this to true when masive registers are done
    }

    setLoading(true)

    try {
      await api.register(values)
      setLoading(false)
      navigate('/auth', { state: { register: true } })
    } catch (e) {
      const error = api.getStrapiError(e)
      setLoading(false)
      actions.setErrors({ email: error })
    }
  }

  return (
    <Auth title='Sign up' subTitle='Hello! Sign up to get cool access.'>
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnChange={false}>
        {({ handleChange, handleSubmit, errors, values, setErrors }: FormikProps<UserData>) => (
          <form method='post' onSubmit={handleSubmit}>
            <InputGroup fullWidth>
              <input type='text' name='fullName' value={values.fullName} onChange={handleChange} placeholder='Full Name' disabled={loading} />
            </InputGroup>
            <InputGroup fullWidth>
              <input type='email' name='email' value={values.email} onChange={handleChange} placeholder='Email Address' disabled={loading} />
            </InputGroup>
            <InputGroup fullWidth>
              <input type='password' name='password' value={values.password} onChange={handleChange} placeholder='Password' disabled={loading} />
            </InputGroup>
            <InputGroup fullWidth>
              <input
                type='password'
                name='passwordConfirm'
                value={values.passwordConfirm}
                onChange={handleChange}
                placeholder='Password Confirmation'
                disabled={loading}
              />
            </InputGroup>
            <Button status='Success' type='submit' shape='SemiRound' fullWidth disabled={loading}>
              Sign up
            </Button>
            {!!Object.keys(errors).length && (
              <Alert closable status='Danger' onClose={() => setErrors({})}>
                {Object.keys(errors).map((key) => (
                  <span key={key}>{errors[key]}</span>
                ))}
              </Alert>
            )}
          </form>
        )}
      </Formik>
      <Group>
        <Link to='/auth/login'>Back to Log In</Link>
      </Group>
    </Auth>
  )
}
export default Login
